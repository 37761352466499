import { useContext, useEffect, useState } from "react";
import { makeRequest } from "../../axios";
import Loading from "../loading/Loading";
import NoItems from "../NoItems/NoItems";
import Post from "../post/Post";
import "./posts.scss";
import { useQuery } from "@tanstack/react-query";
import { AuthContext } from "../../context/authContext";

const Posts = ({ userId }) => {
  const { currentUser } = useContext(AuthContext);
  const [text, setText] = useState(null);
  useEffect(() => {
    if (currentUser && currentUser.storename === userId) {
      setText("Your Store has no products");
    } else {
      setText("this Store has no products");
    }
  }, [currentUser, userId]);
  const { isPending, error, data } = useQuery({
    queryKey: ["UserPost"],
    queryFn: () =>
      makeRequest.get("/posts/user/" + userId).then((res) => {
        return res.data;
      }),
  });
  return (
    <div className="posts">
      {error ? (
        "Something went wrong"
      ) : isPending ? (
        <Loading />
      ) : data && data.length < 1 ? (
        text === "Your Store has no products" ? (
          <NoItems text={text} />
        ) : (
          <div className="position-absolute top-50 start-50 translate-middle text-center">
            <p className="text-capitalize" style={{ width: "70vw" }}>
              {text}
            </p>
            <a href="/home/" className="btn btn-primary">
              Go Back
            </a>
          </div>
        )
      ) : (
        data.map((post) => <Post post={post} key={post.id} />)
      )}
    </div>
  );
};

export default Posts;
