import React from "react";
import { useLocation } from "react-router-dom";
import { makeRequest } from "../../axios";
import { useInfiniteQuery } from "@tanstack/react-query";
import Post from "../../components/post/Post";
import Loading from "../../components/loading/Loading";
import NoItems from "../../components/NoItems/NoItems";
import { useInView } from "react-intersection-observer";
import Load from "../../components/Load";

const Cat = () => {
  const cat = useLocation().pathname.split("/")[2];

  const fetchCatPosts = ({ pageParam = 0 }) =>
    makeRequest
      .get(`/posts/category/${cat}?limit=5&offset=${pageParam}`)
      .then((res) => res.data);

  const {
    data,
    isError,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ["cat", cat],
    queryFn: fetchCatPosts,
    getNextPageParam: (lastPage, pages) => {
      return lastPage.length > 0 ? pages.length * 5 : undefined;
    },
  });

  const allPosts = data ? data.pages.flat() : [];

  const { ref, inView } = useInView();

  const loadMorePosts = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  React.useEffect(() => {
    if (inView) {
      loadMorePosts();
    }
  }, [inView, loadMorePosts]);

  return (
    <div className="posts">
      {isError ? (
        "Something went wrong"
      ) : isLoading && !allPosts.length ? (
        <Loading />
      ) : !allPosts.length ? (
        <NoItems text={"No Items found in this category"} cat={cat} />
      ) : (
        <>
          {allPosts.map((post) => (
            <Post post={post} key={post.id} />
          ))}
          <div
            ref={ref}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              width: "100%",
            }}
          >
            {isFetchingNextPage && (
              <div style={{ position: "fixed", bottom: "0px" }}>
                <Load />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Cat;
