import { useInfiniteQuery } from "@tanstack/react-query";
import { useContext, useState, useEffect, useCallback } from "react";
import { AuthContext } from "../../context/authContext";
import { makeRequest } from "../../axios";
import Post from "../../components/post/Post";
import Loading from "../../components/loading/Loading";
import NoItems from "../../components/NoItems/NoItems";
import { useInView } from "react-intersection-observer";
import "./home.scss";
import Load from "../../components/Load";

const Home = () => {
  const { currentUser } = useContext(AuthContext);
  const [search, setSearch] = useState("");
  const keys = ["storename", "title", "location", "storetitle", "cat", "desc"];

  const fetchPosts = ({ pageParam = 0 }) =>
    makeRequest
      .get(`/posts?limit=5&offset=${pageParam}`)
      .then((res) => res.data);

  const {
    data,
    isError,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ["posts"],
    queryFn: fetchPosts,
    getNextPageParam: (lastPage, pages) => {
      return lastPage && lastPage.length ? pages.length * 5 : undefined;
    },
  });

  const allPosts = data ? data.pages.flat() : [];

  const { ref, inView } = useInView();

  const loadMorePosts = useCallback(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  useEffect(() => {
    if (inView) {
      loadMorePosts();
    }
  }, [inView, loadMorePosts]);

  return (
    <div className="posts">
      <input
        className="form-control me-2 position-sticky"
        type="search"
        placeholder="Search products, stores and categories"
        aria-label="Search"
        onChange={(e) => setSearch(e.target.value)}
      />

      {isError ? (
        "Something went wrong"
      ) : isLoading && !allPosts.length ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Loading />
        </div>
      ) : !allPosts.length ? (
        <NoItems text={"No Items found"} />
      ) : (
        <>
          {allPosts
            .filter((post) =>
              keys.some((key) =>
                post[key]?.toLowerCase().includes(search.toLowerCase())
              )
            )
            .map((post) => (
              <Post post={post} key={post.id} />
            ))}
          <div
            ref={ref}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              bottom: "0px",
              width: "100%",
            }}
          >
            {isFetchingNextPage && (
              <div>
                <Load />
              </div>
            )}
          </div>
        </>
      )}

      {currentUser && (
        <a style={{ textDecoration: "none", color: "black" }} href="/create">
          <button
            className="btn m-3 position-fixed bottom-0 end-0"
            style={{ borderRadius: "50%" }}
          >
            <img
              style={{ maxWidth: "60px", maxHeight: "60px" }}
              src="../../img/add.png"
              alt=""
            />
          </button>
        </a>
      )}
    </div>
  );
};

export default Home;
