import React from "react";

const Privacy = () => {
  return (
    <div className="container mt-5">
      <div className="card">
        <div className="card-body">
          <h2 className="text-center mb-4">
            <b>Privacy Policy</b>
          </h2>
          <p>
            <b>1. Introduction</b>
            <br />
            Salfad Interior Market values the privacy of our users. This Privacy
            Policy explains how we collect, use, and protect your personal
            information.
          </p>
          <p>
            <b>2. Information We Collect</b>
            <br />
            We collect the following information from vendors:
          </p>
          <ul>
            <li>Email address</li>
            <li>WhatsApp number</li>
            <li>Location</li>
            <li>Profile picture</li>
            <li>Social media handles</li>
            <li>Store name</li>
            <li>Username</li>
            <li>Password</li>
          </ul>
          <p>
            For transactions, although we process payments, we do not store card
            details in our database.
          </p>
          <p>
            <b>3. How We Use Your Information</b>
            <br />
            We use the collected information for the following purposes:
          </p>
          <ul>
            <li>To connect buyers with vendors</li>
            <li>To facilitate communication between buyers and vendors</li>
            <li>To manage your account and provide customer support</li>
            <li>
              To process transactions (note: card details are not stored in our
              database)
            </li>
            <li>To improve our services and develop new features</li>
          </ul>
          <p>
            <b>4. Storage of Information</b>
            <br />
            The user ID and password are stored in our secure database. Other
            information, including email, WhatsApp number, location, profile
            picture, social media handles, and store name, are stored in local
            storage.
          </p>
          <p>
            <b>5. Data Security</b>
            <br />
            We implement various security measures to protect your information:
          </p>
          <ul>
            <li>Encryption of sensitive data</li>
            <li>Regular security audits</li>
            <li>Secure servers and databases</li>
          </ul>
          <p>
            <b>6. Sharing of Information</b>
            <br />
            We do not sell, trade, or otherwise transfer your personal
            information to outside parties without your consent, except:
          </p>
          <ul>
            <li>To comply with legal requirements</li>
            <li>To enforce our site policies</li>
            <li>To protect our or others' rights, property, or safety</li>
          </ul>
          <p>
            <b>7. Your Rights</b>
            <br />
            You have the following rights regarding your personal information:
          </p>
          <ul>
            <li>Access your personal information</li>
            <li>Correct or update inaccurate information</li>
            <li>Delete your personal information</li>
            <li>Object to the processing of your personal information</li>
          </ul>
          <p>To exercise these rights, please contact us at [contact email].</p>
          <p>
            <b>8. Changes to Our Privacy Policy</b>
            <br />
            We may update this Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on our website.
            Changes are effective as of the "Last updated" date.
          </p>
          <p>
            <b>9. Contact Us</b>
            <br />
            If you have any questions about this Privacy Policy, please contact
            us at:
          </p>
          <p>
            Email:{" "}
            <a
              style={{ textDecoration: "none" }}
              className="btn btn-primary btn-sm"
              href="mailto:
salfadinteriormarketplace@gmail.com"
            >
              <svg
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
                fill-rule="evenodd"
                clip-rule="evenodd"
              >
                <path d="M24 21h-24v-18h24v18zm-23-16.477v15.477h22v-15.477l-10.999 10-11.001-10zm21.089-.523h-20.176l10.088 9.171 10.088-9.171z" />
              </svg>{" "}
            </a>
            salfadinteriormarketplace@gmail.com{" "}
          </p>
          <p className="text-center">
            Thank you for choosing Salfad Interior Market!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
