import React from "react";
import { makeRequest } from "../../axios";
import { useInfiniteQuery } from "@tanstack/react-query";
import Post from "../../components/post/Post";
import Loading from "../../components/loading/Loading";
import NoItems from "../../components/NoItems/NoItems";
import { useInView } from "react-intersection-observer";
import Load from "../../components/Load";

const Promoted = () => {
  const fetchTopProducts = ({ pageParam = 0 }) =>
    makeRequest
      .get(`/posts/top?limit=5&offset=${pageParam}`)
      .then((res) => res.data);

  const {
    data,
    isError,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ["top"],
    queryFn: fetchTopProducts,
    getNextPageParam: (lastPage, pages) => {
      return lastPage.length > 0 ? pages.length * 5 : undefined;
    },
  });

  const allPosts = data ? data.pages.flat() : [];

  const { ref, inView } = useInView();

  React.useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  return (
    <>
      <p className="text-center fw-b">EXCLUSIVE PRODUCTS</p>
      <div className="posts">
        {isError ? (
          "Something went wrong"
        ) : isLoading && !allPosts.length ? (
          <Loading />
        ) : !allPosts.length ? (
          <NoItems text={"No Items Yet"} />
        ) : (
          <>
            {allPosts.map((post) => (
              <Post post={post} key={post.id} />
            ))}
            <div
              ref={ref}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                width: "100%",
              }}
            >
              {isFetchingNextPage && (
                <div style={{ position: "fixed", bottom: "0px" }}>
                  <Load />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Promoted;
