import React, { useContext, useState } from "react";
import { makeRequest } from "../../axios";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import imageCompression from "browser-image-compression";
import "./style.css";
import Loading from "../../components/loading/Loading";

const Create = () => {
  const state = useLocation().state;
  const [err, setErr] = useState(null);
  const [mainImgBase64, setMainImgBase64] = useState(null);
  const [subAImgBase64, setSubAImgBase64] = useState(null);
  const [subBImgBase64, setSubBImgBase64] = useState(null);
  const [subCImgBase64, setSubCImgBase64] = useState(null);
  const [cat, setCat] = useState(state?.cat || "");
  const [texts, setTexts] = useState({
    title: state?.title || "",
    desc: state?.desc || "",
    price: state?.price || "",
  });
  const [titleWarning, setTitleWarning] = useState(false);
  const [descWarning, setDescWarning] = useState(false);
  const [isLoading, setLoad] = useState(false);

  const { currentUser } = useContext(AuthContext);

  const [id, setId] = useState(currentUser?.id);
  const storename = currentUser?.storename;
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "title") {
      setTitleWarning(value.length >= 100);
    }

    if (name === "desc") {
      setDescWarning(value.length >= 700);
    }

    setTexts((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = async (e, setBase64State) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const options = {
          maxSizeMB: 0.1,
          maxWidthOrHeight: 800,
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(file, options);
        const reader = new FileReader();
        reader.onloadend = () => {
          setBase64State(reader.result);
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoad(true);
      if (state) {
        await makeRequest.post(`/posts/${state.id}`, {
          ...texts,
          cat,
          id,
          mainimg: mainImgBase64 ? mainImgBase64 : state.mainimg,
          subimg1: subAImgBase64 ? subAImgBase64 : state.subimg1,
          subimg2: subBImgBase64 ? subBImgBase64 : state.subimg2,
          submig3: subCImgBase64 ? subCImgBase64 : state.submig3,
        });
        navigate(`/item/${state.id}`, {
          state: { fromm: "Product has been edited" },
        });
      } else {
        await makeRequest.post(`/posts`, {
          ...texts,
          cat,
          id,
          storename,
          mainimg: mainImgBase64,
          subimg1: subAImgBase64,
          subimg2: subBImgBase64,
          submig3: subCImgBase64,
        });
        navigate(`/profile/${storename}`, {
          state: { fromm: "Product has been Added" },
        });
      }
    } catch (err) {
      setErr("Something went wrong");
      setLoad(false);
    }
  };

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="container p-2">
      {state ? (
        <p className="text-center fw-bolder text-decoration-underline">
          Edit {state.title}
        </p>
      ) : (
        <p className="text-center fw-bolder text-decoration-underline">
          Post A Product
        </p>
      )}
      {isLoading ? (
        <div className="one-sub">
          <Loading />
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          {err && <p className="text-center text-danger">{err}</p>}

          {/* product name and description */}
          <div className="row container mb-4">
            <div className="form-floating mb-3 col-12 p-2">
              <input
                type="text"
                className="form-control"
                id="floatingInput"
                placeholder="Product Name"
                name="title"
                onChange={handleChange}
                required
                value={texts.title}
                maxLength="100"
              />
              <label htmlFor="floatingInput">Product Name</label>
              {titleWarning && (
                <p className="text-warning">
                  Title cannot exceed 100 characters.
                </p>
              )}
            </div>

            <div className="mb-3 col-12 p-2">
              <textarea
                className="form-control"
                id="floatingInput"
                placeholder="Product Description"
                name="desc"
                onChange={handleChange}
                rows={8}
                required
                value={texts.desc}
                maxLength="700"
              />
              {descWarning && (
                <p className="text-warning">
                  Description cannot exceed 700 characters.
                </p>
              )}
            </div>

            <div className="form-floating">
              <input
                type="number"
                className="form-control"
                id="floatingPassword"
                placeholder="price(₦)"
                name="price"
                required
                onChange={handleChange}
                value={texts.price}
              />
              <label htmlFor="floatingPassword">Price(₦)</label>
            </div>
          </div>

          <p className="text-center fw-bolder">Set Category</p>
          {/* product category */}
          <div
            className="mt-4"
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <div className="form-check m-2">
              <input
                className="form-check-input"
                type="radio"
                checked={cat === "curtains"}
                name="cat"
                value={"curtains"}
                id="flexRadioDefault1"
                onChange={(e) => setCat(e.target.value)}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault1">
                CURTAINS
              </label>
            </div>

            <div className="form-check m-2">
              <input
                className="form-check-input"
                type="radio"
                checked={cat === "kids"}
                name="cat"
                value={"kids"}
                id="flexRadioDefault1"
                onChange={(e) => setCat(e.target.value)}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault2">
                KIDS ROOM
              </label>
            </div>

            <div className="form-check m-2">
              <input
                className="form-check-input"
                type="radio"
                checked={cat === "bedroom"}
                name="cat"
                value={"bedroom"}
                id="flexRadioDefault1"
                onChange={(e) => setCat(e.target.value)}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault2">
                BEDROOM
              </label>
            </div>

            <div className="form-check m-2">
              <input
                className="form-check-input"
                type="radio"
                checked={cat === "dining"}
                name="cat"
                value={"dining"}
                id="flexRadioDefault1"
                onChange={(e) => setCat(e.target.value)}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault1">
                DINING
              </label>
            </div>

            <div className="form-check m-2">
              <input
                className="form-check-input"
                type="radio"
                checked={cat === "luxury"}
                name="cat"
                value={"luxury"}
                id="flexRadioDefault1"
                onChange={(e) => setCat(e.target.value)}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault2">
                LUXURY
              </label>
            </div>

            <div className="form-check m-2">
              <input
                className="form-check-input"
                type="radio"
                checked={cat === "royal"}
                name="cat"
                value={"royal"}
                id="flexRadioDefault1"
                onChange={(e) => setCat(e.target.value)}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault2">
                ROYAL
              </label>
            </div>

            <div className="form-check m-2">
              <input
                className="form-check-input"
                type="radio"
                checked={cat === "living"}
                name="cat"
                value={"living"}
                id="flexRadioDefault1"
                onChange={(e) => setCat(e.target.value)}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault2">
                LIVING ROOM
              </label>
            </div>

            <div className="form-check m-2">
              <input
                className="form-check-input"
                type="radio"
                checked={cat === "kitchen"}
                name="cat"
                value={"kitchen"}
                id="flexRadioDefault1"
                onChange={(e) => setCat(e.target.value)}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault2">
                KITCHEN
              </label>
            </div>

            <div className="form-check m-2">
              <input
                className="form-check-input"
                type="radio"
                checked={cat === "others"}
                name="cat"
                value={"others"}
                id="flexRadioDefault1"
                onChange={(e) => setCat(e.target.value)}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault2">
                OTHERS
              </label>
            </div>
          </div>

          {/* image input section */}
          <p className="text-center fw-bolder mt-4">Upload Images</p>
          <div className="mt-4 p-2 row">
            <div className="mb-3 col-md-12 p-2 one-sub">
              <label htmlFor="mainimg" className="form-label one-sub">
                <p>Main Image</p>
                <img src="../../img/images.png" alt="" />
              </label>
              <input
                className="form-control"
                style={{ display: "none" }}
                type="file"
                id="mainimg"
                accept="image/png, image/gif, image/jpeg"
                onChange={(e) => handleFileChange(e, setMainImgBase64)}
              />
              {mainImgBase64 && (
                <img src={mainImgBase64} alt="Main" className="imgdhjfd m-1" />
              )}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row ",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            {" "}
            <div className="one-sub">
              <label htmlFor="subimg1" className="form-label one-sub">
                <p>Sub Image</p>
                <img src="../../img/images.png" alt="" />
              </label>
              <input
                style={{ display: "none" }}
                className="form-control"
                type="file"
                id="subimg1"
                accept="image/png, image/gif, image/jpeg"
                onChange={(e) => handleFileChange(e, setSubAImgBase64)}
              />
              {subAImgBase64 && (
                <img src={subAImgBase64} alt="Sub A" className="imgdhjfd m-1" />
              )}
            </div>
            <div className="one-sub">
              <label htmlFor="subimg2" className="form-label one-sub">
                <p>Sub Image</p>
                <img src="../../img/images.png" alt="" />
              </label>
              <input
                style={{ display: "none" }}
                className="form-control"
                type="file"
                id="subimg2"
                accept="image/png, image/gif, image/jpeg"
                onChange={(e) => handleFileChange(e, setSubBImgBase64)}
              />
              {subBImgBase64 && (
                <img src={subBImgBase64} alt="Sub B" className="imgdhjfd m-1" />
              )}
            </div>
            <div className="one-sub">
              <label htmlFor="subimg3" className="form-label one-sub">
                <p>Sub Image</p>
                <img src="../../img/images.png" alt="" />
              </label>
              <input
                className="form-control"
                style={{ display: "none" }}
                type="file"
                id="subimg3"
                accept="image/png, image/gif, image/jpeg"
                onChange={(e) => handleFileChange(e, setSubCImgBase64)}
              />
              {subCImgBase64 && (
                <img src={subCImgBase64} alt="Sub C" className="imgdhjfd m-1" />
              )}
            </div>
          </div>

          <div className="text-center">
            <button className="btn btn-primary w-50" type="submit">
              {state ? "Update Product" : "Post Product"}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default Create;
