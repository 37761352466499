import React, { useContext, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import imageCompression from "browser-image-compression";
import { makeRequest } from "../../axios";
import { AuthContext } from "../../context/authContext";
import Loading from "../../components/loading/Loading";

const UpdateUser = () => {
  const { currentUser } = useContext(AuthContext);

  const id = currentUser?.id;

  const state = useLocation().state;

  const navigate = useNavigate();

  const [file, setFile] = useState(null);
  const [fileBase64, setFileBase64] = useState(null);
  const [err, setErr] = useState(null);
  const [isLoading, setLoad] = useState(false);

  const [texts, setTexts] = useState({
    storetitle: state?.storetitle || "",
    location: state?.location || "",
    phone: state?.phone || "",
    insta: state?.insta || null,
    x: state?.x || null,
    tik: state?.tik || null,
    face: state?.face || null,
    id: id,
  });

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    if (selectedFile) {
      try {
        const options = {
          maxSizeMB: 0.2, // Compress to 200KB
          maxWidthOrHeight: 800, // Maintain a reasonable image dimension
          useWebWorker: true,
        };

        const compressedFile = await imageCompression(selectedFile, options);

        const reader = new FileReader();
        reader.readAsDataURL(compressedFile);
        reader.onloadend = () => {
          setFileBase64(reader.result);
        };
      } catch (error) {
        console.error("Error compressing the image:", error);
      }
    }
  };

  const handleChange = (e) => {
    setTexts((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (state) {
        setLoad(true);

        await makeRequest.post(`/users/`, {
          ...texts,
          profilePic: fileBase64
            ? fileBase64
            : state
            ? state.profilePic
            : "download.png",
        });
        navigate(`/profile/${currentUser.storename}`, {
          state: {
            fromm: "Profile Updated successfully!!!!",
          },
        });
      } else {
        setErr("No post to be updated");
      }
    } catch (err) {
      setErr("Something Went Wrong");
      setLoad(false);
    }
  };

  if (!currentUser) {
    return <Navigate to="/login" />;
  }
  return (
    <div className="container">
      <div className="text-center text-decoration-underline">
        <p>
          <b>Update Your Profile</b>
        </p>
        {err && <p className="text-danger">{err}</p>}
      </div>

      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading />
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="mb-3 col-md-4">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Store Name
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                name="storetitle"
                placeholder="Store Name"
                onChange={handleChange}
                value={texts.storetitle}
              />
            </div>
            <div className="mb-3 col-md-4">
              <label htmlFor="exampleFormControlInput2" className="form-label">
                Location
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleFormControlInput2"
                name="location"
                placeholder="Location"
                onChange={handleChange}
                value={texts.location}
              />
            </div>
            <div className="mb-3 col-md-4">
              <label htmlFor="exampleFormControlInput3" className="form-label">
                Phone Number
              </label>
              <input
                type="tel"
                className="form-control"
                id="exampleFormControlInput3"
                name="phone"
                placeholder="Start With +234"
                onChange={handleChange}
                value={texts.phone}
              />
            </div>
          </div>
          <p className="text-center fw-bold">Add Social Media Accounts</p>
          <div className="mb-3">
            <label htmlFor="exampleFormControlInput3" className="form-label">
              Instagram
            </label>
            <input
              type="url"
              className="form-control form-control-sm"
              id="exampleFormControlInput3"
              name="insta"
              placeholder="Add the link to your Instagram account"
              onChange={handleChange}
              value={texts.insta}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="exampleFormControlInput3" className="form-label">
              𝕏
            </label>
            <input
              type="url"
              className="form-control form-control-sm"
              id="exampleFormControlInput3"
              name="x"
              placeholder="Add the link to your 𝕏 account"
              onChange={handleChange}
              value={texts.x}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="exampleFormControlInput3" className="form-label">
              TikTok
            </label>
            <input
              type="url"
              className="form-control form-control-sm"
              id="exampleFormControlInput3"
              name="tik"
              placeholder="Add the link to your TikTok account"
              onChange={handleChange}
              value={texts.tik}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="exampleFormControlInput3" className="form-label">
              Facebook
            </label>
            <input
              type="url"
              className="form-control form-control-sm"
              id="exampleFormControlInput3"
              name="face"
              placeholder="Add the link to your Facebook account"
              onChange={handleChange}
              value={texts.face}
            />
          </div>
          <p className="text-center">
            <input
              type="file"
              id="file"
              accept="image/png, image/gif, image/jpeg"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <label htmlFor="file" className="form-label text-center mb-5">
              <p>
                <svg
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fillRule="evenodd"
                  clipRule="evenodd"
                >
                  <path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm8.127 19.41c-.282-.401-.772-.654-1.624-.85-3.848-.906-4.097-1.501-4.352-2.059-.259-.565-.19-1.23.205-1.977 1.726-3.257 2.09-6.024 1.027-7.79-.674-1.119-1.875-1.734-3.383-1.734-1.521 0-2.732.626-3.409 1.763-1.066 1.789-.693 4.544 1.049 7.757.402.742.476 1.406.22 1.974-.265.586-.611 1.19-4.365 2.066-.852.196-1.342.449-1.623.848 2.012 2.207 4.91 3.592 8.128 3.592s6.115-1.385 8.127-3.59zm.65-.782c1.395-1.844 2.223-4.14 2.223-6.628 0-6.071-4.929-11-11-11s-11 4.929-11 11c0 2.487.827 4.783 2.222 6.626.409-.452 1.049-.81 2.049-1.041 2.025-.462 3.376-.836 3.678-1.502.122-.272.061-.628-.188-1.087-1.917-3.535-2.282-6.641-1.03-8.745.853-1.431 2.408-2.251 4.269-2.251 1.845 0 3.391.808 4.24 2.218 1.251 2.079.896 5.195-1 8.774-.245.463-.304.821-.179 1.094.305.668 1.644 1.038 3.667 1.499 1 .23 1.64.59 2.049 1.043z" />
                </svg>
                <br /> Select Profile Picture
              </p>
              <br />
              {file && (
                <img
                  width={"100px"}
                  height={"100px"}
                  className="file"
                  alt=""
                  src={URL.createObjectURL(file)}
                />
              )}
            </label>
            <br />
            <input
              type="submit"
              className="btn btn-primary"
              style={{ width: "100%" }}
              value="Update"
            />
            {err && <p className="text-danger">{err}</p>}
          </p>
        </form>
      )}
    </div>
  );
};

export default UpdateUser;
