import "./profile.scss";
import Posts from "../../components/posts/Posts";
import { useQuery } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import { useContext } from "react";
import Loading from "../../components/loading/Loading";
const Profile = () => {
  const { currentUser } = useContext(AuthContext);
  const state = useLocation().state;

  const userId = useLocation().pathname.split("/")[2];

  const { isPending, error, data } = useQuery({
    queryKey: ["user"],
    queryFn: () =>
      makeRequest.get("/users/find/" + userId).then((res) => {
        return res.data;
      }),
  });
  return (
    <div className="profile">
      {error ? (
        <p
          className="text-capitalize position-absolute top-50 start-50 translate-middle text-center"
          style={{ width: "70vw" }}
        >
          User Does Not Exist
        </p>
      ) : isPending ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading />
        </div>
      ) : (
        <>
          <div className="container mt-5">
            {state && (
              <div
                className="alert alert-dark alert-dismissible fade show text-capitalize text-center fixed-bottom"
                role="alert"
              >
                {state.fromm}
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
            )}
            {!currentUser && (
              <div
                className="alert alert-dark alert-dismissible fade show text-capitalize text-center fixed-bottom"
                role="alert"
              >
                If you are a furniture and interior decor vendor, kindly{" "}
                <a href="/register">Register</a> your store to join our growing
                community
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
            )}
            <div className="row">
              <div className="col-md-12 p-2">
                <div className="card text-center">
                  <p className="p-2">
                    <img
                      style={{
                        borderRadius: "50%",
                        width: "200px",
                        height: "200px",
                        border: "1px solid grey",
                      }}
                      src={
                        data.profilePic ? data.profilePic : "../../img/ph.jpg"
                      }
                      className="card-img-top"
                      alt={"Profile"}
                    />
                  </p>
                  <div className="card-body text-center">
                    <h5 className="card-title text-uppercase">
                      {data.storetitle}{" "}
                      {data.verified === "true" && (
                        <img
                          src={"../../img/verified.png"}
                          style={{
                            width: "15px",
                            height: "15px",
                            position: "relative",
                            objectFit: "scale-down",
                            marginBottom: "2px",
                          }}
                          className="card-img-top"
                          alt="..."
                        />
                      )}
                    </h5>
                    <div className="container-fuid">
                      {currentUser &&
                      userId === currentUser.storename &&
                      data.verified === "true" ? (
                        <Link
                          type="button"
                          className="btn btn-primary"
                          to="/update?profile=2"
                          state={data}
                        >
                          Update Profile
                        </Link>
                      ) : currentUser &&
                        userId === currentUser.storename &&
                        !data.verified ? (
                        <div className="text-center">
                          <a
                            href="/verify"
                            type="button"
                            className="btn btn-primary"
                          >
                            Verify Account
                          </a>
                          <br />{" "}
                          <p>
                            Verify your account to complete your profile set-up
                            and be included in our advertisement campaigns!
                          </p>
                        </div>
                      ) : currentUser &&
                        userId === currentUser.storename &&
                        data.verified !== "true" ? (
                        <div className="text-center">
                          <a
                            href={"/verify"}
                            type="button"
                            className="btn btn-primary"
                          >
                            Verify Account
                          </a>
                          <br />{" "}
                          <p>
                            Your verifcation has expired. Renew now to keep
                            enjoying incredible perks!
                          </p>
                        </div>
                      ) : (
                        <a
                          href={`https://wa.me/${data.phone}`}
                          className="btn btn-success"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" />
                          </svg>
                        </a>
                      )}
                      <br />

                      <p
                        className="m-1"
                        style={{ justifyContent: "space-between" }}
                      >
                        {data.face && (
                          <a href={data.face}>
                            <img
                              style={{ maxWidth: "30px", maxHeight: "30px" }}
                              src="../../img/face.png"
                              alt=""
                            />{" "}
                          </a>
                        )}

                        {data.insta && (
                          <a href={data.insta}>
                            <img
                              style={{ maxWidth: "30px", maxHeight: "30px" }}
                              src="../../img/ig.png"
                              alt=""
                            />{" "}
                          </a>
                        )}
                        {data.x && (
                          <a href={data.x}>
                            <img
                              style={{ maxWidth: "30px", maxHeight: "30px" }}
                              src="../../img/x.jpg"
                              alt=""
                            />{" "}
                          </a>
                        )}
                        {data.tik && (
                          <a href={data.tik}>
                            <img
                              style={{ maxWidth: "30px", maxHeight: "30px" }}
                              src="../../img/tik.png"
                              alt=""
                            />{" "}
                          </a>
                        )}
                      </p>
                      <p className="p-5">
                        <img
                          src={data.qr}
                          alt=""
                          style={{ maxWidth: "200px" }}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="card mb-3">
                  <div className="card-header">Basic Information</div>
                  <div className="card-body">
                    <p className="text-capitalize">
                      <strong>Store Name:</strong> {data.storetitle}
                    </p>
                    <p>
                      <strong>Phone:</strong> {data.phone}
                    </p>
                    <p className="text-capitalize">
                      <strong>Address:</strong> {data.location}
                    </p>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">Products</div>
                  <div
                    className="card-body"
                    style={{ height: "500px", overflow: "scroll" }}
                  >
                    <Posts userId={userId} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <a style={{ textDecoration: "none", color: "black" }} href="/create">
        {currentUser && (
          <button
            className="btn m-3 position-fixed bottom-0 end-0"
            style={{ borderRadius: "50%" }}
          >
            <img
              style={{ maxWidth: "60px", maxHeight: "60px" }}
              src="../../img/add.png"
              alt=""
            />
          </button>
        )}
      </a>
    </div>
  );
};

export default Profile;
