import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
} from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import Home from "./pages/home/Home";
import Profile from "./pages/profile/Profile";
import Cat from "./pages/category/Cat"
import Single from "./pages/single/Single"
import Create from "./pages/create/Create";
import UpdateUser from "./pages/updateUser/UpdateUser";
import Promote from "./pages/verify&prpmte/Promote";
import Verify from "./pages/verify&prpmte/Verify";
import Promoted from "./pages/promoted/Promoted";
import ForgotPassword from "./pages/passwordReset/ForgotPassword";
import ResetPassword from "./pages/passwordReset/ResetPassword";
import "./style.scss";
import { useContext } from "react";
import { AuthContext } from "./context/authContext";
import {
  QueryClient,
  QueryClientProvider

} from '@tanstack/react-query' 
import Err404 from "./components/404/Err404";
import Landing from "./pages/land/Landing";
import Terms from "./pages/terms and policies/Terms";
import Privacy from "./pages/terms and policies/Privacy";


function App() {
  const {currentUser} = useContext(AuthContext);


  const queryClient = new QueryClient()

  const Layout = () => {
    return (
      <QueryClientProvider client={queryClient}>

      <div>
        <Navbar />
        <div style={{ display: "flex" }}>
          
          <div style={{ flex: 6 }}>
            <Outlet />
          </div>
          
        </div>
      </div>
      </QueryClientProvider>
    );
  };

  const ProtectedRoute = ({ children }) => {


    return children;
  };

  const router = createBrowserRouter([

    {
      path: "/",
      element: (
        <ProtectedRoute>
          <Layout />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "/",
          element: <Landing />,
        },
        {
          path: "/home",
          element: <Home />,
        },
        {
          path: "/profile/:id",
          element: <Profile />,
        },
        {
          path: "/cat/:cat",
          element: <Cat />,
        },
        {
          path: "/create/",
          element: <Create />,
        },
        {
          path: "/update/",
          element: <UpdateUser />,
        },
        {
          path: "/item/:id",
          element: <Single />,
        },
        {
          path: "/promote/:id",
          element: <Promote />,
        },
        {
          path: "/reset-password/:id",
          element: <ResetPassword />,
        },
        {
          path: "/forgot/",
          element: <ForgotPassword />,
        },
        {
          path: "/verify/",
          element: <Verify />,
        },
        {
          path: "/exclusive/",
          element: <Promoted />,
        },
        {
          path: "/terms",
          element: <Terms />,
        },
        {
          path: "/privacy",
          element: <Privacy />,
        },
        {
          path: "*",
          element: <Err404 />,
        },
      ],
    },
    {
      path: "/login",
      element: <Login />,
    },
  
    {
      path: "/register",
      element: <Register />,
    },

  ]);

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
