import React, { useContext } from "react";
import { AuthContext } from "../../context/authContext";

const NoItems = ({ text }) => {
  const { currentUser } = useContext(AuthContext);

  return (
    <div>
      {currentUser ? (
        <div className="position-absolute top-50 start-50 translate-middle text-center">
          <p className="text-capitalize" style={{ width: "70vw" }}>
            {text}, click the below button to add
          </p>
          <br />
          <a style={{ textDecoration: "none", color: "black" }} href="/create">
            <button className="btn" style={{ borderRadius: "50%" }}>
              <img
                style={{ maxWidth: "80px", maxHeight: "80px" }}
                src="../../img/add.png"
                alt=""
              />
            </button>
          </a>
        </div>
      ) : (
        <div className="position-absolute top-50 start-50 translate-middle text-center">
          <h1>
            <b>
              <p className="text-capitalize" style={{ width: "70vw" }}>
                {text}
              </p>{" "}
            </b>
          </h1>
          <a href="/home/" className="btn btn-primary">
            Go Back
          </a>
        </div>
      )}
    </div>
  );
};

export default NoItems;
