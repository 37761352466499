import "./navbar.scss";
import { useContext } from "react";
import { AuthContext } from "../../context/authContext";

const Navbar = () => {
  const { currentUser, logout } = useContext(AuthContext);

  return (
    <nav className="navbar navbar-expand-sm bg-body-tertiary sticky-top shadow-sm">
      <div className="container-fluid">
        <a className="navbar-brand" href="/home">
          <img
            src="../../img/logo.jpg"
            style={{
              width: "40px",
              height: "40px",
              objectFit: "scale-down",
              border: "1px solid grey",
              borderRadius: "50%",
            }}
            alt=""
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav">
            {currentUser && (
              <li className="nav-item">
                <a
                  className="nav-link active"
                  aria-current="page"
                  href={`/profile/${currentUser.storename}/`}
                >
                  Dashboard
                </a>
              </li>
            )}
            <li className="nav-item">
              <a className="nav-link active fw-bolder" href="/exclusive">
                Exclusive Products
              </a>
            </li>

            <li className="nav-item dropdown">
              <div
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Categories
              </div>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="/cat/luxury">
                    Luxury
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/cat/royal">
                    Royal
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/cat/office">
                    Office
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/cat/living-room">
                    Living Room
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/cat/bedroom">
                    Bed Room
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/cat/kids">
                    Kids Room
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/cat/dining">
                    Dining
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/cat/curtains">
                    Curtains
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/cat/lights">
                    Lights
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/cat/kitchen">
                    Kitchen
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/cat/others">
                    Others
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              {currentUser && currentUser.id ? (
                <span
                  className="nav-link"
                  style={{ cursor: "pointer" }}
                  onClick={logout}
                >
                  Logout
                </span>
              ) : (
                <a className="nav-link" href="/login">
                  Sign In As Vendor
                </a>
              )}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
