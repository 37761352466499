import React, { useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { makeRequest } from "../../axios";
import { AuthContext } from "../../context/authContext";
import { useNavigate } from "react-router-dom";
import PaymentButton from "../../components/payment/Payment";
import Loading from "../../components/loading/Loading";

const Promote = () => {
  const [count, setCount] = useState(1);
  const [price, setPrice] = useState(30000);
  const [err, setErr] = useState(null);
  const { currentUser } = useContext(AuthContext);
  const [isLoading, setLoad] = useState(false);

  const id = currentUser?.id;

  const navigate = useNavigate();

  useEffect(() => {
    if (count < 1) {
      setCount(1);
    } else if (count >= 12) {
      setPrice(28000);
    } else {
      setPrice(30000);
    }
  }, [count]);

  const increment = () => {
    setCount(count + 1);
  };

  const decrement = () => {
    setCount(count - 1);
  };

  const finalPrice = count * price;

  const formattedPrice2 = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(finalPrice);

  const formattedPrice = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(price);

  const handleVerify = async (e) => {
    try {
      setLoad(true);

      await makeRequest.post(`/verify/`, { count, id: id });
      navigate(`/profile/${currentUser.storename}`, {
        state: {
          fromm: "Account verified successfully!!!",
        },
      });
    } catch (err) {
      setErr(err);
    }
  };
  console.log(err);

  return (
    <div className="container mt-5">
      <div className="text-center mb-4">
        <h1 className="display-4">
          <b>Verify Account</b>
        </h1>
      </div>

      {isLoading ? (
        <Loading />
      ) : (
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body justify-content-between">
                <p className="count-display">
                  Number of Months:{" "}
                  <span className="badge bg-primary">{count}</span>
                </p>
                <p className="count-display">
                  Price Per Month:{" "}
                  <span className="badge bg-info">{formattedPrice}</span>
                </p>
                <p className="count-display">
                  Total Price:{" "}
                  <span className="badge bg-success">{formattedPrice2}</span>
                </p>
              </div>
              <div className="card-footer d-flex justify-content-between">
                <button className="btn btn-sm btn-danger" onClick={decrement}>
                  Decrement
                </button>
                <PaymentButton
                  runSaveHandleItemPurchased={handleVerify}
                  finalPrice={finalPrice}
                />

                <button className="btn btn-sm btn-success" onClick={increment}>
                  Increment
                </button>
              </div>
              <p className="text-center text-success text-capitalize">
                activate a massive discount by verifying your account for 1 year
                and above
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Promote;
