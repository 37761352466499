import React from "react";
import "./land.css";

const Landing = () => {
  return (
    <div className="landing">
      <div className="jumbotron text-center">
        <h1 className="display-4">Welcome to Salfad Interior Market!</h1>
        <p className="lead">Buy, Sell, Connect</p>
        <hr className="my-4" />
        <p className="p-1">
          Discover unique interior decor pieces, sell your creations, and
          connect with designers, stores and buyers.
        </p>
        <a
          className="btn btn-primary m-1 text-capitalize"
          href="/register"
          role="button"
        >
          Register your store
        </a>
        <a
          className="btn btn-success m-1 text-capitalize"
          href="home"
          role="button"
        >
          explore products
        </a>
      </div>
      <div className="container p-4 mb-5">
        <div className="row text-center">
          <div className="col-md-4">
            <h2 className="text-secondary">Buy</h2>
            <p>
              Explore a wide range of unique and stylish decor items from
              various sellers.
            </p>
          </div>
          <div className="col-md-4">
            <h2 className="text-secondary">Sell</h2>
            <p>
              Showcase and sell your creative decor pieces to a broad audience.
            </p>
          </div>
          <div className="col-md-4">
            <h2 className="text-secondary">Connect</h2>
            <p>Engage with other decor enthusiasts, designers, and buyers.</p>
          </div>
        </div>
      </div>
      <div className="footer text-center position-fixed bottom-0 mt-2">
        <p>
          By continuing, you have accepted our{" "}
          <a href="/terms">Terms of Service</a> and{" "}
          <a href="/privacy">Privacy Policy</a>.
        </p>
      </div>
    </div>
  );
};

export default Landing;
