import "./post.scss";

const Post = ({ post }) => {
  const formattedPrice = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(post.price);

  return (
    <div className="row shadow-sm">
      <div className="card p-3" style={{ width: "17rem" }}>
        <a
          style={{ textDecoration: "none", color: "black" }}
          href={`/item/${post.id}/`}
        >
          <img
            src={post.mainimg}
            style={{
              width: "100%",
              height: "150px",
              position: "relative",
              objectFit: "cover",
            }}
            className="card-img-top mt-1 rounded-2"
            alt="..."
          />
        </a>
        <div className="card-body">
          <a
            style={{ textDecoration: "none", color: "black" }}
            href={`/item/${post.id}`}
          >
            <h5
              className="card-title text-uppercase"
              style={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                overflow: "hidden",
                textOverflow: "ellipsis",
                lineHeight:
                  "1.2em" /* Adjust line height to match your design */,
              }}
            >
              {post.promoted === "true" && (
                <img
                  src={"../../img/Goldbadge.png"}
                  style={{
                    width: "14px",
                    height: "14px",
                    position: "relative",
                    objectFit: "scale-down",
                  }}
                  className="card-img-top mb-1"
                  alt="..."
                />
              )}{" "}
              {post.title}
            </h5>
          </a>
          <div className="opacity-75 text-capitalize text-sm-start text-decoration-underline">
            {formattedPrice}
          </div>
          <div className="opacity-75 text-capitalize text-sm-end position-absolute end-0 p-2">
            <a
              style={{
                textDecoration: "none",
                color: "black",
              }}
              href={`/profile/${post.storename}/`}
            >
              <p
                style={{
                  width: "140px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "clip",
                }}
              >
                {" "}
                <p></p>
                {post.verified === "true" && (
                  <img
                    src={"../../img/verified.png"}
                    style={{
                      width: "12px",
                      height: "12px",
                      position: "relative",
                      objectFit: "scale-down",
                    }}
                    className="card-img-top mb-1"
                    alt="..."
                  />
                )}
                {post.storetitle} @{post.storename}
                <p>
                  {" "}
                  <img
                    src={"../../img/location.png"}
                    style={{
                      width: "12px",
                      height: "12px",
                      position: "relative",
                      objectFit: "scale-down",
                    }}
                    className="card-img-top mb-1"
                    alt="..."
                  />
                  {post.location}
                </p>
              </p>{" "}
            </a>{" "}
          </div>

          <p className="card-text"></p>
          <a
            href={`https://api.whatsapp.com/send?phone=${post.phone}&text=%20Hi%20I%20would%20like%20to%20enquire%20about%20this%20product%20https://salfad.com/item/${post.id}`}
            className="btn btn-success"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Post;
